import { Card, Col, Container, Modal, Row } from 'react-bootstrap'
import { useState } from 'react'
import { EffectCoverflow, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import ia from '../../data/ia.json'
import './IACards.css'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'


const IACards = () => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    return (
        <>
            <Container className='iaCards'>
                <Row className='justify-content-center'>
                    <Col>  
                        <Swiper
                            effect={'coverflow'}
                            centeredSlides={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                            }}
                            pagination={false}
                            modules={[EffectCoverflow, Pagination]}
                            className='mySwiper'
                            spaceBetween={10}
                            loop={true}>
                            {
                                ia.map(elm => {
                                    return (
                                        <SwiperSlide key={elm.id}>
                                            <Card>
                                                <img src={elm.img} 
                                                     alt={elm.name} 
                                                     title={elm.name} 
                                                     onClick={handleShow} />
                                                <Card.Body>
                                                    <p>{elm.name}</p>
                                                </Card.Body>
                                            </Card >
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        
            <Modal show={show} onHide={handleClose} centered>
                <button className='close' onClick={handleClose}>
                    <FontAwesomeIcon icon={faAngleLeft} /> 
                    <FormattedMessage
                        id='ia.back1'
                        defaultMessage='Volver'/>
                </button>
                <Modal.Body>
                {
                    ia.map(elm => {
                        return (
                            <Card key={elm.id}>
                                <img src={elm.img} alt={elm.name} title={elm.name} />
                                <Card.Body>
                                    <p>{elm.name}</p>
                                </Card.Body>
                            </Card >
                        )
                    })
                }
                </Modal.Body>
                <button className='close' onClick={handleClose}>
                    <FontAwesomeIcon icon={faAngleLeft} /> 
                    <FormattedMessage
                        id='ia.back2'
                        defaultMessage='Volver'/>
                </button>
            </Modal>
        </>
    )
}

export default IACards