import { Card, Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import business from '../../data/business.json'
import './MyBusiness.css'


const MyBusiness = () => {
    return (
        <section className='myBusiness'>
            <h2 data-aos='fade-right'>
                <FormattedMessage
                    id='business.title1'
                    defaultMessage='Servicios y Apps'/>
            </h2>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col xl={5} className='my-2' data-aos='fade-left'>
                        <h3>
                            <FormattedMessage
                                id='business.title2'
                                defaultMessage='Servicios'/>
                        </h3>
                        <ul>
                            <li>
                                <FormattedMessage
                                    id='business.text1'
                                    defaultMessage='Desarrollo de sitios web'/>
                            </li>
                            <li>
                                <FormattedMessage
                                    id='business.text2'
                                    defaultMessage='Desarrollo de Aplicaciones Web y Saas sin bases de datos'/>
                            </li>
                            <li>
                                <FormattedMessage
                                    id='business.text3'
                                    defaultMessage='Creación de Tiendas en Shopify'/>
                            </li>
                        </ul>
                    </Col>
                    <Col xl={6} className='my-2' data-aos='fade-right'>
                            <h3>
                                <FormattedMessage
                                    id='business.title3'
                                    defaultMessage='Aplicaciones'/>
                            </h3>
                            <Row className='justify-content-center align-items-center px-4'>
                            {
                                business.map(elm => {
                                    const cardStyle = {
                                        backgroundColor: elm.backgroundColor
                                    }

                                    return (
                                        <Col md={5} xl={12} xxl={6} key={elm.id}>
                                            <a href={elm.link} 
                                               target={'_blank'} 
                                               rel='noopener noreferrer'>
                                                <Card style={cardStyle}>
                                                    <img src={elm.img} 
                                                         alt={elm.alt}
                                                         title={elm.name}/>
                                                    <div className='shadow'></div>
                                                    <h3>{elm.name}</h3>
                                                </Card >
                                            </a>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default MyBusiness