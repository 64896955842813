import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import './Social.css'


const Social = () => {
    return (
        <footer className='social fixed-bottom mb-4'>
            <a href='https://twitter.com/thebulliam' 
               target={'_blank'} 
               rel='noopener noreferrer'
               aria-label='Access my profile on Twitter and follow me'>
               <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href='https://instagram.com/thebulliam' 
               target={'_blank'} 
               rel='noopener noreferrer'
               aria-label='Access my profile on Instagram and follow me'>
                <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href='https://github.com/thebulliam' 
               target={'_blank'} 
               rel='noopener noreferrer'
               aria-label='Access my profile on Github and visit my projects'>
                <FontAwesomeIcon icon={faGithub} />
            </a>
        </footer>
    )
}

export default Social