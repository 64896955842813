import { Card, Col, Container, Row } from 'react-bootstrap'
import apps from '../../data/apps.json'
import './AppsCards.css'


const AppsCards = () => {
    return (
        <Container fluid className='apps-cards'>
            <Row>
                {
                    apps.map(elm => {
                        return (
                            <Col md={6} xl={4} key={elm.id}>
                                <Card data-aos='zoom-in'>
                                    <img src={elm.img} alt={elm.name} title={elm.name}/>
                                    <Card.Body>
                                        <h4>{elm.name}</h4>
                                        <p>{elm.text}</p>
                                        <h4>Tecnologías</h4>
                                        <p>{elm.techs}</p>
                                    </Card.Body>
                                    <Card.Footer> 
                                        {
                                            elm.demo && (
                                                <a href={elm.demo} target='_blank' rel="noopener noreferrer">
                                                    Visit
                                                </a>
                                            )
                                        }
                                        {
                                            elm.github && (
                                                <a href={elm.github} target='_blank' rel="noopener noreferrer">
                                                    View on Github
                                                </a>
                                            )
                                        }
                                    </Card.Footer>
                                </Card >
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default AppsCards