import React, { createContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import Spanish from './../data/es-ES.json'
import English from './../data/en-US.json'
import Portugues from './../data/pt-PT.json'

const langContext = createContext()

const LangProvider = ({ children }) => {
	let localeDefault = 'es-ES'
	let messagesDefault = Spanish
	const lang = localStorage.getItem('lang')

	if (lang) {
		localeDefault = lang

		if (lang === 'en-US') {
			messagesDefault = English
		} else if (lang === 'pt-PT') {
			messagesDefault = Portugues
		}
	}

	const [messages, setMessages] = useState(messagesDefault)
	const [locale, setLocale] = useState(localeDefault)

	const establecerLenguaje = (lang) => {
		switch (lang){
			case 'es-ES':
				setMessages(Spanish)
				setLocale('es-ES')
				localStorage.setItem('lang', 'es-ES')
				break
			case 'en-US':
				setMessages(English)
				setLocale('en-US')
				localStorage.setItem('lang', 'en-US')
				break
			case 'pt-PT':
				setMessages(Portugues)
				setLocale('pt-PT')
				localStorage.setItem('lang', 'pt-PT')
				break
			default:
				setMessages(Spanish)
				setLocale('es-ES')
				localStorage.setItem('lang', 'es-ES')
		}
	}

	return (
		<langContext.Provider value={{ establecerLenguaje }}>
			<IntlProvider locale={locale} messages={messages}>
				{children}
			</IntlProvider>
		</langContext.Provider>
	)
}
 
export { LangProvider, langContext }