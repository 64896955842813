import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Typewriter from 'typewriter-effect'
import AOS from 'aos'
import './Header.css'


const Header = () => {
    useEffect(() => {
        AOS.init({ duration : 2000 })
        AOS.refresh()
    }, [])

    return (
        <header>
            <Card className='brand'>
                <p>
                    <FormattedMessage
                        id='header.hello'
                        defaultMessage='👋 Bienvenidos a'/>
                </p>
                <figure>
                    <h1 data-aos='fade-down'>The Bulliam</h1>
                    <figcaption>
                        <img
                            src='/img/logo2.png'
                            alt='Logo The Bulliam' 
                            title='Logo The Bulliam'
                            width={'300px'}
                            height={'200px'}
                            data-aos='fade-in' />
                    </figcaption>
                    <h2 data-aos='fade-zoom-in'>
                        Solopreneur
                    </h2>
                    <Typewriter 
                        options={{
                            strings: [
                                'Web Apps',
                                'Generative AI',
                                'E-commerce'
                            ],
                            autoStart: true,
                            loop: true,
                        }} />
                </figure>
                <section data-aos='fade-down'>
                    <FontAwesomeIcon icon={faChevronDown} />
                </section>
            </Card>
        </header>
    )
}

export default Header