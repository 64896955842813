import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import './HomeProjects.css'


const HomeProjects = () => {
    return (
        <main className='homeProjects'>
            <article>
                <h2>
                    <FormattedMessage
                        id='homeProjects.title'
                        defaultMessage='Visita mis proyectos'/>
                </h2>
                <section>
                    <Link className='button' to={'/code-garage'}>
                        <FormattedMessage
                            id='homeProjects.button1'
                            defaultMessage='Garaje código'/>
                    </Link>
                    <Link className='button' to={'/digi-art-wall'}>
                        <FormattedMessage
                            id='homeProjects.button2'
                            defaultMessage='Muro Digi-Art'/>
                    </Link>
                </section>
            </article>
        </main>
    )
}

export default HomeProjects