import { Card, Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import './About.css'


const About = () => {
    return (
        <section className='about'>
            <h2 data-aos='fade-right'>
                <FormattedMessage
                    id='about.title'
                    defaultMessage='Sobre mí'/>
            </h2>
            <Container fluid>
                <Row>
                    <Col md={9}>
                        <Card>
                            <p data-aos='fade-right'>
                                <FormattedMessage
                                    id='about.description1'
                                    defaultMessage='Hola, ¿qué tal? Soy The Bulliam. Desde que tengo memoria, 
                                    me he movido entre líneas de código en la red. Convertí mi pasión por la 
                                    tecnología en mi trabajo, mezclando aprendizaje online desde mi habitación 
                                    con cursos de formación en escuelas de desarrollo web.'/>
                            </p>
                            <p data-aos='fade-left'> 
                                <FormattedMessage
                                    id='about.description2'
                                    defaultMessage='En mi vida pasada, aprendí a resolver problemas, a negociar 
                                    y a lidiar con la presión del mundo digital. En el día, y sobretodo en las 
                                    noches, no solo fabrico software, fabrico conexiones, desde cualquier lugar. 
                                    La seguridad y la privacidad para mí son tan importantes como tú. Desde 
                                    blindar tus datos hasta implementar las últimas tecnologías, estoy aquí 
                                    para ser tu aliado en este viaje hacia el futuro digital.'/>
                            </p>
                            <p data-aos='fade-left'> 
                                <FormattedMessage
                                    id='about.description3'
                                    defaultMessage='¿Estás listo para unirte a la revolución tecnológica? 
                                    ¡Con The Bulliam, el futuro es nuestro!'/>
                            </p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img 
                            src='../../img/vector1.png' 
                            className='vector img-fluid' 
                            alt='Vector ciudad'
                            title='Vector ciudad'
                            width={'1000'}
                            height={'auto'} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default About