import IACards from '../IACards/IACards'
import { FormattedMessage } from 'react-intl'
import './IA.css'


const IA = () => {
    return (
        <main className='ia'>
            <h2 data-aos='fade-right'>
                <FormattedMessage
                    id='ia.title'
                    defaultMessage='Imágenes creadas con IA'/>
            </h2>
            <IACards />
        </main>
    )
}

export default IA