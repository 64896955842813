import Nav from '../components/Nav/Nav'
import Apps from '../components/Apps/Apps'
import Social from '../components/Social/Social'
import Footer from '../components/Footer/Footer'


const WebApps = () => {
    return (
        <>
            <Nav />
            <Apps />
            <Social />
            <Footer />
        </>
    )
}

export default WebApps