import Nav from '../components/Nav/Nav'
import Graphic from '../components/Graphic/Graphic'
import IA from '../components/IA/IA'
import Social from '../components/Social/Social'
import Footer from '../components/Footer/Footer'


const GraphicDesign = () => {
    return (
        <>
            <Nav />
            <Graphic />
            <IA />
            <Social />
            <Footer />
        </>
    )
}

export default GraphicDesign