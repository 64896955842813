import GraphicCards from '../GraphicCards/GraphicCards'
import { FormattedMessage } from 'react-intl'
import './Graphic.css'


const Graphic = () => {
    return (
        <main className='graphic'>
            <h2 data-aos='fade-right'>
                <FormattedMessage
                    id='graphic.title'
                    defaultMessage='Diseño gráfico'/>
            </h2>
            <p>
                <FormattedMessage
                    id='graphic.text'
                    defaultMessage='Para ver todos los diseños, pasa cada publicación con el 👆 dedo 
                    o con el 🖱 ratón, hacia la izquierda o la derecha'/>
            </p>
            <GraphicCards />
        </main>
    )
}

export default Graphic