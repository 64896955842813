import { Card, Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
// import ContactForm from '../ContactForm/ContactForm
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import './Contacts.css'


const Contacts = () => {
    return (
        <main className='contacts'>
            <h1 data-aos='fade-right'>
                <FormattedMessage
                    id='contact.title'
                    defaultMessage='Contacta conmigo'/>
            </h1>
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <h2>
                                <FormattedMessage
                                    id='contact.send'
                                    defaultMessage='Envíame un correo'/>
                            </h2>
                            <a href='mailto:guillermo.gpf@protonmail.com' 
                               target='_blank' 
                               rel="noopener noreferrer">
                                guillermo.gpf@protonmail.com
                            </a>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <h2>
                                <FormattedMessage
                                    id='contact.social'
                                    defaultMessage='Mis redes sociales'/>
                            </h2>
                            <section>
                                <a href='https://twitter.com/thebulliam' 
                                   target={'_blank'} 
                                   rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faXTwitter} />
                                </a>
                                <a href='https://instagram.com/thebulliam' 
                                   target={'_blank'} 
                                   rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href='https://github.com/thebulliam' 
                                   target={'_blank'} 
                                   rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faGithub} />
                                </a>
                            </section>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default Contacts