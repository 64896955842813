import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { LangProvider } from './context/lang.context'
// import { MessageProviderWrapper } from './context/message.context'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Router>
        <LangProvider>
            {/* <MessageProviderWrapper> */}
                <App />
            {/* </MessageProviderWrapper> */}
        </LangProvider>
    </Router>
)