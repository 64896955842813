import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import './HomeContact.css'


const HomeContact = () => {
    return (
        <section className='homeContact'>
            <article>
                <h2>
                    <FormattedMessage
                        id='homeContact.title'
                        defaultMessage='Hablemos'/>
                </h2>
                <Link className='button mt-4' to={'/contact'}>
                    <FormattedMessage
                        id='homeContact.button'
                        defaultMessage='Contacta conmigo'/>
                </Link>
            </article>
        </section>
    )
}

export default HomeContact