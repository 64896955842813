import Nav from '../components/Nav/Nav'
import Header from '../components/Header/Header'
import About from '../components/About/About'
import HomeProjects from '../components/HomeProjects/HomeProjects'
import MyBusiness from '../components/MyBusiness/MyBusiness'
import HomeContact from '../components/HomeContact/HomeContact'
import Social from '../components/Social/Social'
import Footer from '../components/Footer/Footer'


const Home = () => {
    return (
        <>
            <Nav /> 
            <Header />
            <About />
            <HomeProjects />
            <MyBusiness />
            <HomeContact />
            <Social />
            <Footer />
        </>
    )
}

export default Home