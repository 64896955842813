import AppRoutes from './routes/AppRoutes'
// import Message from './components/Message/Message'
import './App.css'


function App() {
    return (
        <div className='App'>
            <AppRoutes />
            {/* <Message /> */}
        </div>
    )
}

export default App