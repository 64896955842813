import Nav from '../components/Nav/Nav'
import Contacts from '../components/Contacts/Contacts'
import Social from '../components/Social/Social'
import Footer from '../components/Footer/Footer'

 
const Contact = () => {
    return (
        <>
            <Nav />
            <Contacts />
            <Social />
            <Footer />
        </>
    )
}

export default Contact